import React, { useState, useEffect, useRef, useLayoutEffect } from "react"
import tw from "twin.macro"
import { useWindowSize, isEDMode } from "@components/utils"

import PrimaryCta from "@components/PrimaryCta"
import { heading1 } from "@styles/ui"
import ihLandingVideoDesktop from "@videos/ih-landing-video-desktop.mp4"
import ihLandingVideoMobile from "@videos/ih-landing-video-mobile.mp4"

import transparentLogo from "@images/logos/xywav-logo-icon-transparent-IH.svg"
import { isBrowser } from "./utils"

const AnimatedHero = () => {
  const [animateContent, setAnimateContent] = useState(false)
  const { width } = useWindowSize()
  const ref = useRef(null)

  // fixes bug where video dissapears when user goes to another tab and then returns to the page on Safari
  const handlePageshow = () => {
    ref.current.play()
  }

  useEffect(() => {
    window.addEventListener("focus", handlePageshow)
    return () => {
      window.removeEventListener("focus", handlePageshow)
    }
  }, [])

  useLayoutEffect(() => {
    //handle screenshots
    if (isBrowser && isEDMode) {
      setAnimateContent(true)
    }
  }, [])

  const content = {
    videos: { desktop: ihLandingVideoDesktop, mobile: ihLandingVideoMobile },
    text: (
      <div tw="lg:max-w-[670px] xl:max-w-[820px]">
        <h1 css={[heading1, tw`pb-6 lg:pb-3.5`]}>
          Idiopathic Hypersomnia (IH) is more than feeling tired during the day
        </h1>
        <p tw="text-white text-xl mb-6 lg:(text-3xl leading-[1.35] mb-3.5)">
          Ask your doctor about XYWAV, the only FDA-approved treatment studied
          across multiple symptoms for adults with IH.
        </p>

        <div css={[tw`text-center relative z-20 lg:text-left`]}>
          <PrimaryCta
            width={tw`w-[280px] lg:w-[350px]`}
            url="/idiopathic-hypersomnia/what-is-xywav/"
            mdSmall
          >
            Tell me more
          </PrimaryCta>
        </div>
      </div>
    ),
  }

  // Triggers the animation after a set duration from when the video plays
  const handleVideoUpdate = e => {
    const animateAtMilliseconds = width < 1024 ? 0 : 4000
    if (!animateContent && e.timeStamp >= animateAtMilliseconds) {
      setAnimateContent(true)
    }
  }

  return (
    <section css={[tw`relative w-full overflow-hidden  lg:(min-h-[362px])`]}>
      {/* Video */}
      <div tw="hidden lg:(flex justify-end absolute right-0 bottom-0 top-[-18%] w-[35%])">
        <div tw="relative after:(absolute block h-full -left-4 top-0 bottom-0 w-20 [background: linear-gradient(to right, rgba(255,255,255,1) 40%,rgba(255,255,255,0) 100%)])"></div>
        <video
          ref={ref}
          muted
          autoPlay
          playsInline
          src={content.videos.desktop}
          onTimeUpdate={handleVideoUpdate}
          css={[tw`h-full max-w-[unset] object-left lg:(object-right)`]}
        />
      </div>
      {/* Copy */}
      <div tw="relative z-[2] max-w-5xl mx-auto pt-12 pb-[30px] px-4 md:pt-20 lg:(pt-28 pb-[40px]) xl:(px-0 max-w-6xl)">
        {/* Background logo */}
        <img
          src={transparentLogo}
          css={[
            tw`absolute -bottom-8 right-[-35px] w-[225px] z-[1] lg:(bottom-[unset] w-[550px] left-[-255px]) xl:(w-[790px] top-[85px])`,
          ]}
        />
        <div tw="relative z-[1]">{content.text}</div>
      </div>

      {/* Gradient */}
      <div
        css={[
          tw`w-full absolute h-full top-0 left-0 overflow-hidden transition-transform [transition-duration: 1s] [transition-timing-function: linear]  [background-image: linear-gradient(77.59deg, #7047BD 55%, #9474CE 80%, rgba(174,149,218,0.5) 98.75%)]`,
          tw`lg:([clip-path: polygon(0 0,65% 0,73% 100%,0 100%)]) xl:([clip-path: polygon(0 0,90% 0,100% 100%,0 100%)] w-[77%])`,
        ]}
      />
    </section>
  )
}

export default AnimatedHero
