import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"

// Components
import Layout from "@components/layout"
import Section from "@components/section"
import Container from "@components/container"
import Seo from "@components/seo"
import LearnMore from "@components/learnMore"
import AnimatedHero from "@components/animatedHero"
import PrimaryCta from "@components/PrimaryCta"
import KeyPoints from "@components/keyPoints"
import PatientVoice from "@components/PatientVoice"
import InlineVideo from "@components/inlineVideo"
import InlineVideoSection from "@components/inlineVideoSection"
import Anchor from "@components/Anchor"
import { Heading2 } from "@styles/ui"
import Callout from "@components/callout"

const keyPoints = [
  {
    image: "/images/icons/round/clipboard-icon.svg",
    imageAlt: "Clipboard icon",
    title: (
      <span tw="text-2xl font-ffcocon font-bold leading-7 md:(min-h-[56px] block)">
        What are the symptoms of Idiopathic Hypersomnia?
      </span>
    ),
    text: (
      <p tw="font-medium text-sm md:text-xl">
        Excessive daytime sleepiness may be most common but experiencing severe
        and prolonged difficulty waking up (also known as sleep inertia) or not
        feeling refreshed after a nap could be symptoms of
        Idiopathic&nbsp;Hypersomnia.
      </p>
    ),
    cta: {
      text: "Know your symptoms",
      url: "/idiopathic-hypersomnia/what-is-ih/",
    },
  },
  {
    image: "/images/icons/round/person-sleeping-desk-icon.svg",
    imageAlt: "Person sleeping at desk icon",
    title: (
      <span tw="text-2xl font-ffcocon font-bold leading-7 md:(min-h-[56px] block)">
        How do I talk with my doctor about XYWAV?
      </span>
    ),
    text: (
      <>
        <p tw="font-medium text-sm md:text-xl">
          Take this quiz to see how Idiopathic Hypersomnia impacts you, like
          needing to set multiple alarms or finding it hard to concentrate.
        </p>
      </>
    ),
    cta: {
      text: "TAKE THE QUIZ",
      url: "/idiopathic-hypersomnia/talking-to-your-doctor/",
    },
  },
  {
    image: "/images/icons/round/medicine-bottle-icon.svg",
    imageAlt: "Medicine bottle icon",
    title: (
      <span tw="text-2xl font-ffcocon font-bold leading-7 md:(min-h-[56px] block)">
        How is XYWAV taken?
      </span>
    ),
    text: (
      <p tw="font-medium text-sm md:text-xl">
        XYWAV offers individualized dosing options. It&apos;s most often taken
        twice nightly, but it can also be taken once nightly.
      </p>
    ),
    cta: {
      text: "TAKING XYWAV",
      url: "/idiopathic-hypersomnia/dosing/",
    },
  },
]

const learnMoreCtas = [
  {
    text: "Find out how to get XYWAV",
    url: "/idiopathic-hypersomnia/getting-xywav/",
  },
  {
    text: "Stay connected throughout your journey",
    url: "/idiopathic-hypersomnia/stay-connected/",
  },
]

const IhHomePage = ({ location, data }) => {
  return (
    <Layout location={location}>
      <Seo
        title="XYWAV® for Idiopathic Hypersomnia (IH) Treatment | XYWAV for IH"
        description="XYWAV® (calcium, magnesium, potassium, and sodium oxybates) is the only FDA-approved Idiopathic Hypersomnia (IH) treatment studied across multiple symptoms for adults. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <AnimatedHero />

      {/* Key points */}
      <Section bgColour={tw`bg-lilac`}>
        <Container>
          <img
            src={"/images/logo-colourful-opacity.svg"}
            tw="hidden xl:(absolute block -left-[380px] top-[-35px] w-[780px] opacity-75)"
            alt=""
          />
          <p
            css={tw`relative z-1 text-center text-lg font-medium mb-7 md:(text-2xl mb-10)`}
          >
            <strong>
              Unlike stimulants and wake-promoting agents that are taken during
              the day, XYWAV is different&mdash;it&apos;s taken at night to help
              treat multiple symptoms of Idiopathic Hypersomnia, such as
              excessive daytime sleepiness, sleep inertia (severe grogginess or
              confusion when waking&nbsp;up), long sleep time, and cognitive
              impairment.
            </strong>
          </p>

          <KeyPoints
            points={keyPoints}
            mdWrap
            iconSize={tw`md:(w-[146px] h-[146px])`}
          />
        </Container>
      </Section>

      {/* Quote */}
      <PatientVoice
        quote={
          <>
            My life used to be consumed by sleep, but taking XYWAV twice nightly
            helps me fight daytime sleepiness. I wake up each morning with less
            sleep inertia and don&apos;t need to nap as much during the
            day.&rdquo;
          </>
        }
        patient={"Diana"}
        diagnosis={"XYWAV IH Ambassador"}
        imageData={data}
        altText={"Patient Diana sharing her XYWAV® story"}
        cta={{
          text: "Discover more stories and resources",
          url: "/idiopathic-hypersomnia/resources/",
        }}
        theme="ih"
      />

      {/* Call out */}
      <Callout
        heading="Stay in the loop!"
        cta={{
          text: "SIGN UP NOW",
          url: "/idiopathic-hypersomnia/stay-connected/",
        }}
      >
        <>
          <p tw="xl:mb-8 sm:mb-5">
            We&apos;re here to support you with information on Idiopathic
            Hypersomnia, starting XYWAV, tips for talking with your doctor,
            JazzCares<sup>&reg;</sup>, and more. Join our email list today.
          </p>
        </>
      </Callout>

      {/* Financial assistance */}
      <Section bgColour={tw`bg-lilac`}>
        <Container>
          <div tw="flex flex-col">
            <div tw="order-2 lg:(max-w-[570px] pr-12) xl:pr-0">
              <h2 css={[Heading2, tw`mb-6 text-center lg:text-left`]}>
                JazzCares is here to help
              </h2>
              <p css={tw`text-center mb-7 lg:(mb-10 text-left)`}>
                See if you&apos;re eligible for one of the XYWAV cost savings
                programs. You could pay as little as $5 a month for XYWAV.
                <sup>*&dagger;&Dagger;&sect;</sup>
              </p>
              <div tw="text-center lg:text-left">
                <PrimaryCta url="/idiopathic-hypersomnia/jazzcares-for-xywav/">
                  Show me how to save
                </PrimaryCta>
              </div>
            </div>
            <div tw="mt-7 order-3 lg:(mt-10)">
              <p tw="mb-2 text-[12px] lg:text-xs">
                *For patients with commercial insurance (HMO, PPO,
                employer-sponsored, etc.)
              </p>
              <p tw="mb-2 text-[12px] lg:text-xs">
                <sup>&dagger;</sup>Subject to a maximum annual benefit.
              </p>
              <p tw="mb-2 text-[12px] lg:text-xs">
                <sup>&Dagger;</sup>You must be a resident of the US, Puerto
                Rico, or other US territory.
              </p>
              <p tw="mb-2 text-[12px] lg:text-xs">
                <sup>&sect;</sup>You must be enrolled in the XYWAV and XYREM
                REMS.
              </p>
              <p tw="text-[12px] lg:text-xs">
                Jazz Pharmaceuticals reserves the right to terminate or modify
                this program at any time with or without notice. Other{" "}
                <Anchor
                  link="https://jazzcares.com/patients/xywav/#termsandconditions"
                  isExternal
                  newTab
                >
                  Terms and Conditions
                </Anchor>{" "}
                apply.
              </p>
            </div>
            <img
              src={"/images/five-dollar-bill-icon.svg"}
              alt="$5 bill icon"
              tw="block order-1 mx-auto w-[350px] h-[201px] mb-7 lg:(absolute top-0 right-0 w-[470px] h-[270px])"
            />
          </div>
        </Container>
      </Section>

      {/* Video */}
      <InlineVideoSection
        video={
          <InlineVideo
            wistiaId="t7w25f5jby"
            thumbnail={data.thumbImage}
            tall
            alt="Patient Jennie treatment story video"
          />
        }
      >
        <div tw="text-sm md:text-xl">
          <p>
            Jennie, a real patient and XYWAV IH Ambassador, shares her treatment
            story.
          </p>
          <p tw="font-extrabold my-5">
            Ready to get started? XYWAV offers support at every step of the
            way—from personal mentors to tracking tools in the myWAV app.
          </p>
          <Anchor
            link="/idiopathic-hypersomnia/patient-support-programs/"
            hasArrow
            addedStyles={tw`font-extrabold`}
          >
            Learn more
          </Anchor>
        </div>
      </InlineVideoSection>

      <LearnMore ih colour="purple" ctas={learnMoreCtas} />
    </Layout>
  )
}

export default IhHomePage

export const query = graphql`
  query {
    thumbImage: file(
      relativePath: { eq: "ih-home/patient-jennie-treatment-video.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    patientVoice: file(
      relativePath: { eq: "ih-home/patient-diana-ih-ambassador-quote.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 705, placeholder: NONE)
      }
    }
    patientVoiceMobile: file(
      relativePath: {
        eq: "ih-home/patient-diana-ih-ambassador-quote-mobile.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
  }
`
